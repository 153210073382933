import moment from "moment/moment";

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const truncate = (str: string, length: number): string => {
    if (!str || str.length === 0) return str;

    return (
        str.length > length
            ? `${str.slice(0, length)}...`
            : str
    );
};

export const unixTimestampToFrFormat = (timestamp: number, separator: string = '/'): string => moment(timestamp * 1000).format(`DD${separator}MM${separator}YYYY`);